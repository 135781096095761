<template>
  <div>
    <PatientButton @getPatientInfo="getPatientInfo" />
    <van-tabs color="#1691fe" title-active-color="#1691fe" @click="changeTab">
      <van-tab
        v-for="(item, index) in tabOptions"
        :key="index"
        :title="item.label"
        :name="item.value"
      />
    </van-tabs>
    <div v-if="list.length > 0" class="appointmentList">
      <div v-for="(item, index) in list" :key="index" class="item">
        <div class="title">
          {{ item.date }}
        </div>
        <div class="list">
          <div class="grid-table">
            <div>缴费凭证</div>
            <div>{{ item.number }}</div>
            <div>缴费金额</div>
            <div>{{ item.fee }}元</div>
          </div>
        </div>
      </div>
    </div>
    <van-empty v-else description="暂无缴费信息，请重新查询" />
  </div>
</template>

<script>
  import store from '@/store'
  import moment from 'moment'

  import PatientButton from '@/components/PatientButton'
  import { Dialog, Toast } from 'vant'
  import { getPayList } from '@/api/his/his'
  export default {
    name: 'Pay',
    components: { PatientButton },
    data() {
      return {
        active: '全部',
        list: [],
        search: {
          PatientType: 1,
          PatientId: '',
          KSSJ: '',
          JSSJ: '',
        },
        tabOptions: [
          {
            value: 1,
            label: '门诊',
          },
          {
            value: 2,
            label: '住院',
          },
        ],
      }
    },
    async created() {
      let userInfo = JSON.parse(store.getters['user/userInfo'])
      if (userInfo != null) {
        this.search.PatientId = userInfo.patinetId
        await this.pageInit()
        await this.fetchData()
      } else {
        Dialog.alert({
          message: '暂未绑定就诊人，点击确定前往。',
          closeOnPopstate: false,
        }).then(() => {
          this.$router.push({
            path: '/message',
            query: { sign: 'gzrgl' },
          })
        })
      }
    },
    beforeDestroy() {
      Dialog.close()
    },
    methods: {
      //初始化
      async pageInit() {
        let data = JSON.parse(sessionStorage.getItem('day'))
        this.day = data.day
        this.month = data.jfMonth
        this.search.KSSJ = moment(new Date())
          .add(-this.month, 'month')
          .format('yyyy-MM-DD')
        this.search.JSSJ = moment(new Date()).format('yyyy-MM-DD')
      },
      async fetchData() {
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { data } = await getPayList(this.search)
        this.list = data
        Toast.clear()
      },

      changeTab(name) {
        this.search.PatientType = name
        this.fetchData()
      },

      async getPatientInfo() {
        let userInfo = JSON.parse(store.getters['user/userInfo'])
        if (userInfo != null) {
          this.search.PatientId = userInfo.patinetId
          await this.fetchData()
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .van-tabs {
    width: 95%;
    margin: 0.4rem auto auto;
    background: #fff;
    border-radius: 10px;
    font-size: 0.426rem;
    overflow: hidden;
  }
  .appointmentList {
    width: 95%;
    margin: 0.4rem auto auto;
    background: #fff;
    border-radius: 10px;
    font-size: 0.426rem;
    overflow: hidden;
    font-family: 'Microsoft Yahei';
    .title {
      font-weight: bolder;
      padding: 0.3rem;
      background: linear-gradient(77deg, #e0ebfd 2%, #ffffff 96%, #ffffff 96%);
      color: #1691fe;
      span {
        display: inline-block;
        float: right;
        color: #28a881;
      }
      span.gray {
        color: #acacac;
      }
    }

    .list {
      padding: 15px;
    }
  }

  .grid-table {
    display: grid;
    grid-template-columns: 1.5fr 5fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    div:nth-child(odd) {
      color: #9e9e9e;
      font-size: 0.4rem;
    }
    .dept {
      color: #646464;
      font-size: 0.4rem;
    }
  }
</style>
