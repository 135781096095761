import { request } from '@/util/request'

export function getAccountList(data) {
  return request({
    url: '/api/WecAccount/GetAccountList',
    method: 'Post',
    data,
  })
}

export function addAccount(data) {
  return request({
    url: '/api/WecAccount/AddAccount',
    method: 'Post',
    data,
  })
}

export function deleteAccount(params) {
  return request({
    url: '/api/WecAccount/DeleteAccount',
    method: 'get',
    params,
  })
}

export function changeDefaultAccount(params) {
  return request({
    url: '/api/WecAccount/ChangeDefaultAccount',
    method: 'get',
    params,
  })
}

export function getUserInfo(params) {
  return request({
    url: '/api/WecAccount/GetAccountInfo',
    method: 'GET',
    params,
  })
}

export function getOptions(params) {
  return request({
    url: '/api/WecAccount/GetOptions',
    method: 'GET',
    params,
  })
}
